Platform = BaseEntity.extend({

	initialize: function() {
		this._width = 0;
		this._height = 0;
	},

	getWidth: function() {
		return this._width;
	},
	getHeight: function() {
		return this._height;
	},
	dirname: function (path) {
	  // http://kevin.vanzonneveld.net
	  // +   original by: Ozh
	  // +   improved by: XoraX (http://www.xorax.info)
	  // *     example 1: dirname('/etc/passwd');
	  // *     returns 1: '/etc'
	  // *     example 2: dirname('c:/Temp/x');
	  // *     returns 2: 'c:/Temp'
	  // *     example 3: dirname('/dir/test/');
	  // *     returns 3: '/dir'
	  return path.replace(/\\/g, '/').replace(/\/[^\/]*\/?$/, '');
	},
	basename: function (path, suffix) {
	  // http://kevin.vanzonneveld.net
	  // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	  // +   improved by: Ash Searle (http://hexmen.com/blog/)
	  // +   improved by: Lincoln Ramsay
	  // +   improved by: djmix
	  // *     example 1: basename('/www/site/home.htm', '.htm');
	  // *     returns 1: 'home'
	  // *     example 2: basename('ecra.php?p=1');
	  // *     returns 2: 'ecra.php?p=1'
	  var b = path.replace(/^.*[\/\\]/g, '');

	  if (typeof(suffix) == 'string' && b.substr(b.length - suffix.length) == suffix) {
	    b = b.substr(0, b.length - suffix.length);
	  }

	  return b;
	},

	getTileComponents: function(ts, index) {

		if(ts.properties) {
			if(ts.properties["components"]) {
				return ts.properties["components"];
			}
		}

		if(ts.tileproperties) {
			if(ts.tileproperties[index] && ts.tileproperties[index]["components"]) {
				return ts.tileproperties[index]["components"];
			}
		}

		return "";
	},

	makeTiles: function(ts, drawType) {
		//var components, i, posx, posy, sMap, sName, tHeight, tName, tNum, tWidth, tsHeight, tsImage, tsProperties, tsWidth, xCount, yCount, _ref;
		//tsImage = ts.image, tNum = ts.firstgid, tsWidth = ts.imagewidth;
		//tsHeight = ts.imageheight, tWidth = ts.tilewidth, tHeight = ts.tileheight;
		var tsProperties = ts.tileproperties;
		var xCount = ts.imagewidth / ts.tilewidth | 0;
		var yCount = ts.imageheight / ts.tileheight | 0;
		var sMap = {};

		for(var i = 0, ref = yCount * xCount; i < ref; i++) {
			var posx = i % xCount;
			var posy = i / xCount | 0;
			var sName = "tileSprite" + ts.firstgid;
			var tName = "tile" + ts.firstgid;
			sMap[sName] = [posx, posy];

			var components = "2D, " + drawType;

			var tileComponents = this.getTileComponents(ts, i.toString());

			if(tileComponents.length)
			{
				components += ", ";
				components += tileComponents;
			}
			Crafty.c(tName, {
				comp: components,
				init: function() {
					this.addComponent(this.comp);
					return this;
				}
			});
		}
		Crafty.sprite(ts.tilewidth, ts.tileheight, ts.image, sMap);
	},
	makeLayer: function(layer) {
		for(var i = 0, len = layer.data.length; i < len; i++) {
			var tDatum = layer.data[i];
			if(tDatum) {
				var tile = Crafty.e("tile" + tDatum,  + ", tileSprite" + tDatum);
				tile.x = (i % layer.width) * tile.w;
				tile.y = (i / layer.width | 0) * tile.h;
			}
		}
	},
	loadTiledFile: function(file, callback) {
		model = this;

		$.ajax({
			async: false,
			dataType: 'json',
			cache: false,
			url: file
		}).done(function(data) {

			var tiles = data.tilesets;
			var layers = data.layers;

			var tileImages = [];

			for(var i = 0, len = tiles.length; i < len; i++) {
				tiles[i].image = "web/images/" + model.basename(tiles[i].image);
				tileImages.push(tiles[i].image);
			}
			Crafty.load(tileImages, function() {
				for(var i = 0, len = tiles.length; i < len; i++) {
					model.makeTiles(tiles[i], gameContainer.conf.get('renderType'));
				}
				for(var i = 0, len = layers.length; i < len; i++) {
					model.makeLayer(layers[i]);
				}

				this._width = data.width * data.tilewidth;
				this._height = data.height * data.tileheight;

				callback();
			});

		}).error(function(request, status) {
			window.location = 'web/error.html';
			alert(status);
		})

		this.set({
			'entity': model
		});
	},

	loadMapFile: function(file, callback) {

		model = this;

		$.ajax({
			async: false,
			dataType: 'text',
			cache: false,
			url: file
		}).done(function(data) {
			// Split out each row
			$.each(data.split("\n"), function(y, row) {
				// Then split out each column
				$.each(row, function(x, column) {
					var type = null;

					switch(column) {
					case '*':
						type = "ground, solid";
						break;
					case '#':
						type = "cave, solid";
						break;
					case '~':
						type = "water, solid";
						break;
					case 'S':
						type = "slime";
						break;
					case 'F':
						type = "fly";
						break;
					case '?':
						type = "bonus";
						break;
					}

					if(type != null) {
						var e = Crafty.e("2D, " + gameContainer.conf.get('renderType') + ", " + type + ", Collision");
						e.attr({
							x: x * e.w,
							y: y * e.h,
							z: 50
						});
						e.collision();

						model._width = Math.max(model._width, e.x + e.w);

						model._height = Math.max(model._height, e.y + e.h);
					}

				});


			});

			if(callback) callback();
		}).error(function(request, status) {
			window.location = 'web/error.html';
			alert(status);
		})

		this.set({
			'entity': model
		});
	},
});