/**
    examples:             
    'sprites_name' : {
         'file' : '/images/path/to/file',
         'tile' : width,
         'tileh' : height,
         'elements': {
             'sprite_name' : [0, 0]
         }
    },
*/

Sprites = Backbone.Model.extend({
    defaults: {
        images:{
            'amos' : {
                'file' : '/images/amos.png',
                'tile' : 75,
                'tileh' : 96,
                'elements': {
                    'amos' : [0, 0],
                    'jumping' : [0,11],
                    'front' : [0,12],
                    'side' : [0,13]
                }
            },
            'platforms' : {
                'file' : '/images/platforms.png',
                'tile' : 70,
                'tileh' : 70,
                'elements' : {
                    'ground' : [0,0],
                    'cave' : [1,0],
                    'block' : [2,0],
                    'crate' : [3,0],
                    'bonus' : [4,0],
                    'bonus_used' : [5,0]
                }
            },
            'clouds' : {
                'file' : '/images/clouds.png',
                'tile' : 129,
                'tileh' : 63,
                'elements': {
                    'cloud1' : [0,0],
                    'cloud2' : [1,0],
                    'cloud3' : [2,0]
                }
            },
            'slime' : {
                'file' : '/images/slime.png',
                'tile' : 43,
                'tileh' : 28,
                'elements': {
                    'slime' : [0,0],
                    'slime_walk' : [1,0],
                    'slime_dead' : [2,0]
                }
            },
            'fly' : {
                'file' : '/images/fly.png',
                'tile' : 69,
                'tileh' : 32,
                'elements': {
                    'fly' : [0,0],
                    'fly_walk' : [1,0],
                    'fly_dead' : [2,0]
                }
            },
            'hillshort' : {
                'file' : '/images/hill_short.png',
                'tile' : 48,
                'tileh' : 102,
                'elements': {
                    'hillshort' : [0,0]
                }
            },
            'hilllong' : {
                'file' : '/images/hill_long.png',
                'tile' : 48,
                'tileh' : 146,
                'elements' : {
                    'hilllong' : [0,0]
                }
            },
            'water' : {
                'file' : '/images/water.png',
                'tile' : 70,
                'tileh' : 45,
                'elements' : {
                    'water' : [0,0]
                }
            },
            'bush' : {
                'file' : '/images/bush.png',
                'tile' : 70,
                'tileh' : 26,
                'elements' : {
                    'bush' : [0,0]
                }
            },
            'shroom' : {
                'file' : '/images/shroom.png',
                'tile' : 39,
                'tileh' : 34,
                'elements' : {
                    'shroom' : [0,0]
                }
            },
            'spikes' : {
                'file' : '/images/spikes.png',
                'tile' : 70,
                'tileh' : 35,
                'elements' : {
                    'spikes' : [0,0]
                }
            },
            'grass' : {
                'file' : '/images/grass.png',
                'tile' : 35,
                'tileh' : 40,
                'elements' : {
                    'grass' : [0,0]
                }
            },
            'coin' : {
                'file' : '/images/coin.png',
                'tile' : 35,
                'tileh' : 35,
                'elements' : {
                    'coin' : [0,0]
                }
            },
            'fences' : {
                'file' : '/images/fences.png',
                'tile' : 70,
                'tileh' : 61,
                'elements' : {
                    'fence' : [0,0],
                    'fence_broken' : [1, 0]
                }
            }
        }
    },
    initialize: function(){
        
    },
    /**
     * Create Crafty sprites from images object
     * Pass key if You want create only one choosen sprite.
     * 
     * @param  string key - sprite definition key
     */
    create: function(key){
        if(key != undefined){
            element = this.get('images')[key];
            if(element['tileh'] == undefined)
                Crafty.sprite(element['tile'], element['file'], element['elements']);
            else
                Crafty.sprite(element['tile'], element['tileh'], element['file'], element['elements']);
    		
            return true;
        };

        _.each(this.get('images'), function(element, k){ 
            if(element['tileh'] == undefined)
                Crafty.sprite(element['tile'], element['file'], element['elements']);
            else
                Crafty.sprite(element['tile'], element['tileh'], element['file'], element['elements']);
        });

    },
    /**
     * Get path for sprites files - for loading
     * 
     * @return array array of files paths
     */
    getFiles: function(){
        var array = [], i=0;
        _.each(this.get('images'), function(element, key){ 
            array[i] = element['file']
            i++;
        });

        return array;
    }
});