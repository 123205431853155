Score = BaseEntity.extend({
	defaults: {
        'text' : "Score",
        'score' : "0"
    },
    initialize: function(){
    	var model = this;
    	var entity = Crafty.e("Static, DOM, Text");
        this._x = Crafty.viewport.width-100;
        this._y = 50;

    	entity
            .attr({x: model._x, y: model._y, w: 200, z: 1000})
            .text(model.get('text') + ": " + model.get('score'))
            .textColor('#000')
            .textFont({'size' : '20px', 'family': 'Arial'});

    	model.set({'entity' : entity });
    },
    setScore: function(score) {
        this.set({'score': score});
        this.getEntity().text(this.get('text') + ": " + this.get('score'));
    },
    setLabel: function(label) {
        this.set({'text': label});
        this.getEntity().text(this.get('text') + ": " + this.get('score'));
    }
});