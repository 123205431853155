Background = BaseEntity.extend({
    _bg: 0,
    _bgWidth: 0,

    initialize: function() {
        this._elements = [];

        this.set({'entity': this});
    },

    destroy: function() {
        for(var i =0; i < this._elements.length; i++) {
            this._elements[i].destroy();
        }
    },

    addScrollableImage: function(sprite, repeat, speed, posX, posY) {

        var img = Crafty.e("Scrollable, Image");
        
        img.setSpeed(speed)
            .image(imageName, repeat)
            .attr({x:posX, y:posY});

        this._elements.push(img);

        return img;
    },

    addAutoSprite: function(sprite, speed, posX, posY) {
        var img = this.addSprite(sprite, speed, posX, posY);

        img.autoScroll();

        return img;
    },

    addSprite: function(sprite, speed, posX, posY) {

        var img = Crafty.e("Scrollable, " + sprite);
        
        img.setSpeed(speed)
            .attr({x:posX, y:posY});

        this._elements.push(img);

        return img;
    },

    setBackground: function(image) {
        Crafty.background("url(" + image + ") ");

        var actualImage = new Image();
        var model = this;

        actualImage.onload = function() {
            model._bgWidth = actualImage.width;
        }
        actualImage.src = image;
        this._bgWidth = actualImage.width;

    },

    setColor: function(color) {
        Crafty.background(color);
    },

    scroll: function(direction) {
        for(var i = 0; i < this._elements.length; i++) {
            if(this._elements[i].autoScrolling) continue;

            this._elements[i].scroll(direction);
        }

        if( this._bg - Crafty.viewport.width > -this._bgWidth)
        {
            this._bg -= direction;

            if(this._bg <= 0)
                Crafty.stage.elem.style.backgroundPosition = this._bg + "px 0px";
        }
    
    }
});