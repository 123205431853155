function clamp(min, val, max) {
  return Math.min(max, Math.max(min, val));
}

Amos = BaseEntity.extend({
  defaults: {
    'speed': 200,
    'jump': 380,
    'bonus': 0
  },

  initialize: function() {
    var model = this;

    model._direction = 0;
    var entity = Crafty.e("2D, " + gameContainer.conf.get('renderType') + ", Twoway, Keyboard, SpriteAnimation, amos, Collision, Gravity");
    entity.twoway(model.get('speed'), model.get('jump')).attr({
      x: ((Crafty.viewport.width / 2) - (entity.w / 2)),
      y: Crafty.viewport.height / 2,
      z: 300
    })

      //.collision([20,0],[entity.w-20,0],[entity.w,20], [entity.w, entity.h-20], [entity.w-10, entity.h-15], [entity.w-20, entity.h], [20,entity.h], [10,entity.h-15], [0, entity.h-20], [0, 25])

    .collision()

      .gravity("solid").sprite(12, 0, 1, 1).bind("EnterFrame", function() {
        if(model._waiting) {
          model._waiting++;

          if(model._waiting > 200) {
            this.sprite(12, 0, 1, 1);
            model._waiting = 0;
          }
        }
      })
      .checkHits('Collision')
      .bind('Move', function(e) {

        if(model._waiting) model._waiting = 0;

        var collision = this.hit('Collision')
        if (collision) {
          for (var i in collision) {
            target = collision[i]

            if (target.ny < 0) {
              continue;
            }

            if (target.type === 'SAT') {
              this.x -= target.overlap * target.nx;
              this.y -= target.overlap * target.ny;
            } else {
              this.x = e._x;
              this.y = e._y;
            }

            targetBottom = target.obj.y + target.obj.h;

            // if jumping and below the collision
            if (this._up && targetBottom <= this.y) { 
              this.vy = 0;
            }

            if (target.obj.has("bonus")) {
              amosCenter = this.x + (this.w/2);

              // and amos colided below an object...
              if (targetBottom <= this.y && amosCenter >= target.obj.x && amosCenter <= target.obj.x + target.obj.w) {

                // test for bonus object
                var e  = Crafty.e("2D, " + gameContainer.conf.get('renderType') + ", bonus_used, Collision" );
                e.attr({ x: target.obj.x, y: target.obj.y, z: 51 });
                target.obj.destroy();

                // increment score
                model.set('bonus', model.get('bonus') + 1);
                sc["score"].setScore(model.get('bonus'));
              }
            }
          }
        }

        var direction = this.x > e._x ? 1 : this.x < e._x ? -1 : 0;

        sc["background"].scroll(direction);

        var x = -(this.x + (this.w / 2) - (Crafty.viewport.width / 2));
        var y = -(this.y + (this.h / 2) - (Crafty.viewport.height / 2));

        var platform = sc["platform"];

        var minX = -(platform.getWidth() - Crafty.viewport.width);
        var minY = -(platform.getHeight() - Crafty.viewport.height);

        Crafty.viewport.scroll('_x', x.clamp(minX, 0));
        Crafty.viewport.scroll('_y', y.clamp(minY, 0));

        if(this.x + this.w > platform.getWidth()) 
          this.x = e._x;

        if(this.y + this.h > platform.getHeight())
        {
          window.location.reload(false);
        }

      }).bind('LandedOnGround', function(ground) {
        this.sprite(0, model._direction, 1, 1);
        this._up = false;
        if (this.isPlaying()) {
          this.resumeAnimation();
        }
      }).bind('CheckJumping', function(ground) {
        this._up = true;
        this.pauseAnimation();
        this.sprite(11, model._direction, 1, 1);
      })  
      .reel("walk_left", 400,0,1,10)
      .reel("walk_right", 400,0,0,10)

    //change direction when a direction change event is received
      .bind("NewDirection", function(direction) {

        if (this._up) {
          return;
        }

        if(direction.x < 0) {
          model._direction = 1;
          if (!this.isPlaying("walk_left")) {
            this.animate("walk_left", -1);
          }
        } else if(direction.x > 0) {
          model._direction = 0;
          if (!this.isPlaying("walk_right")) {
            this.animate("walk_right", -1);
          }
        } else if(!direction.x && !direction.y) {
          model._waiting = 1;
          if (this.isPlaying()) {
            this.pauseAnimation();
            this.resetAnimation();
          }
        }
      }).setName('Amos');

    entity.origin(entity.w / 2, entity.h / 2);

    this.set({
      'entity': entity
    });
  }
});