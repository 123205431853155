

Crafty.scene("main", function() {

  var assets = ["/world/1/background1.png"]

  Crafty.load({ "images": assets }, function() {

    var platform = new Platform();

    //Crafty.sprite(320, 240, "web/world/1/background1.png", {"background1": [0,0]});

    platform.loadMapFile("/world/1/1.map");

    var background = new Background();
    //background.setBackground('web/world/1/background1.png');
    background.addAutoSprite('cloud1', 0.2, 5, 20);
    background.addAutoSprite('cloud2', 0.4, 200, 100);
    background.addAutoSprite('cloud3', 0.6, 250, 190);

    //background.addSprite("background1", 0.1, 150, 300);

    //background.addSprite('hillshort', 2, 140, 310);
    //background.addSprite('hilllong', 2, 490, 260);

    var amos = new Amos();

    var score = new Score();
    score.xOff = 700;
    score.yOff = 50;

    sc["background"] = background;   
    sc["amos"] = amos;
    sc["platform"] = platform;
    sc["score"] = score;

  })

});

