Crafty.c("Static", {
	xOff: 0,
	yOff: 0,
	init: function() {
		this.requires("2D");
		this.bind("EnterFrame", function(e) {
			this.x = -Crafty.viewport.x + this.xOff;
			this.y = -Crafty.viewport.y + this.yOff;
		});
	}
});