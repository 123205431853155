Crafty.c("Movable", {
  init: function() {
    this.requires('Twoway, Keyboard, SpriteAnimation');
  },

  movable: function(speed, jumpspeed) {
    this.twoway(speed, jumpspeed)//, {UP_ARROW: -90, DOWN_ARROW: 90, RIGHT_ARROW: 0, LEFT_ARROW: 180})
    //this._speed = speed;

      .reel("walk_left", 0,0,0,10)
      .reel("walk_right", 0,0,1,10)
    //change direction when a direction change event is received
      .bind("NewDirection",
        function (direction) {
          console.log('moveable new direction ' + direction);
          if (direction.x < 0) {
            if (!this.isPlaying("walk_left"))
              this.animate("walk_left", -1);
          }
          if (direction.x > 0) {
            if (!this.isPlaying("walk_right"))
              this.animate("walk_right", -1);
          }
          if (direction.y < 0) {
            if (!this.isPlaying("walk_up"))
              this.animate("walk_up", -1);
          }
          if (direction.y > 0) {
            if (!this.isPlaying("walk_down"))
              this.animate("walk_down", -1);
          }
          if(!direction.x && !direction.y) {
            this.pauseAnimation();
            this.resetAnimation();
          }
        })
    return this;
  }

});