Crafty.c("Scrollable", {
    _speed: 2,
    _autoScrolling: false,

    init: function() {
        this.requires("2D, "+gameContainer.conf.get('renderType')+"");
    },

    setSpeed: function(speed) {
        this.speed = speed;
        return this;
    },

    autoScroll: function() {
        this.bind("EnterFrame", function() {
            this.scroll(1);
        });
        this.autoScrolling = true;
    },

    getSpeed: function() {
        return this.speed;
    },

    scroll: function(direction) {
        this.x -= this.speed * direction;

        if (this.x + this.w < -Crafty.viewport.x) {
            this.x = Crafty.viewport.width + -Crafty.viewport.x;
        }
        else if(this.x > Crafty.viewport.width + -Crafty.viewport.x) {
            this.x = -this.w;
        }
    }
});